/* eslint-disable */
import styled from "styled-components"

export const Layout = styled.div`
  width: 100%;
  padding-bottom: 3rem;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url(${require("../../images/contact/bg_contact.png")});
  background-color: #0f1722;

  @media only screen and (max-width: 992px) {
    margin-bottom: -30px;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0rem auto;
  @media only screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    margin: 0rem auto;
    flex-wrap: wrap;
  }
`
export const ContainerSup = styled(Container)`
  @media only screen and (max-width: 992px) {
    width: 100%;
    display: flex;
    margin: 0rem auto;
    flex-wrap: wrap;
  }
`

export const Infomation = styled.div`
  margin: 3rem auto;
`

export const Wrapper = styled.div`
  text-align: center;
  color: #fff;
  margin: 3rem auto;
`
export const Title = styled.div`
  font-size: 1.875rem;
  font-weight: bold;
  font-family: Lato;
`
export const SubTitle = styled.div`
  font-size: 1.5rem;
  margin-bottom: 8px;
  font-weight: bold;
  font-family: Lato;
`
export const Time = styled.div`
  font-size: 1.5rem;
  margin: 37px auto;
  max-width: 290px;
  line-height: 1.3;
  font-family: Lato;
  letter-spacing: 0.5px;
`
export const Address = styled.div`
  font-size: 1.125rem;
  color: #8c9bb3;
  margin: 0px auto;
  line-height: 1.3;
  letter-spacing: 0.5px;
`
export const Phone = styled.span`
  text-decoration: underline;
  color: white;
  font-size: 1.25rem;
  margin: 0 8px;
  // letter-spacing: 1px;
`
export const Email = styled(Phone)``

export const MemberContainer = styled.div`
  border: 1px solid #fff;
  text-align: center;
  border-radius: 30px;
  margin: 10px;
  background: #fff;
  color: #000;
  padding: 25px 0px;
  max-width: 300px;
  width: 300px;
`
export const MemberName = styled.div`
  font-size: 1.25rem;
  font-family: Lato;
  font-weight: bold;
  color: #0f1722;
`

export const Icon = styled.img`
  margin: -6px 23px;
  margin-left: 0px;
`

export const MemberPosition = styled.div`
  font-size: 1rem;
  margin: 20px 0px;
  color: #8c9bb3;
  font-weight: 500;
`
export const MemberEmail = styled(MemberName)`
  font-size: 1rem;
  margin-bottom: 20px;
  color: #1283ff;
`
export const MemberPhone = styled.div`
  font-size: 0.875rem;
  color: #a8b3c5;
  font-weight: 500;
  font-family: Lato;
`
export const MemberImg = styled.div`
  width: 68px;
  height: 68px;
  border: 1px solid #cfcfcf;
  background-color: #cfcfcf;
  margin: 0 auto 20px;
  overflow: hidden;
  position: relative;
  border-radius: 50%;
`
export const AvatarImage = styled.img`
  max-width: 100%;
  height: auto;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
`
