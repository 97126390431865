/*  eslint-disable */
import React from "react"
import PropTypes from "prop-types"

import { Footer } from "src/sections/landing"

import Contact from "../components/ContactPage"
import Helmet from "react-helmet"

const ContactPage = ({ ...props }) => (
  <React.Fragment>
    <Helmet>
      <meta name="robots" content="all" />
    </Helmet>
    <Contact {...props} />
    <Footer />
  </React.Fragment>
)

ContactPage.propTypes = {
  location: PropTypes.any.isRequired,
}

export default ContactPage
